@import "_fonts";

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

p {
  margin-bottom: 0;
}

.wrapper {
  min-width: 320px;
  font-family: 'Libre Franklin', sans-serif;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

img {
  display: block;
}

.container {
  max-width: 100%;
  margin: auto;
  padding-left: 75px;
  padding-right: 75px;
  justify-content: space-between;
}

.side-block {
  width: calc(50% - 15px);
}

.header {
  position: relative;
  z-index: 10;

  .container {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 30px;
  }
}

.main {
  margin-top: -100px;
  padding-top: 150px;
  margin-bottom: 100px;

  .h1 {
    font-weight: 900;
    font-size: 64px;
    line-height: 115%;
    text-transform: uppercase;
    color: #f74aae;
    margin-bottom: 0;
  }

  .arrow {
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 30px;

    .text {

      p {
        color: #3e3e3e;
        font-size: 32px;
        line-height: 125%;
        font-weight: 400;
        margin-bottom: 40px;

        b {
          color: #f74aae;
          font-weight: 900;
        }
      }

      span {
        display: block;
        font-weight: 500;
        font-size: 28px;
        text-transform: uppercase;
        color: #f74aae;
        margin-bottom: 15px;
        font-style: italic;
      }

      img {
        margin-bottom: 15px;
      }
    }

    .arrow-img {
      margin-right: 60px;
    }
  }
}

.side-block:first-child {
  position: relative;
  z-index: 5;

  .form {
    position: relative;
    z-index: 5;
    filter: drop-shadow(0 20px 40px rgba(146, 146, 146, 0.25));
    border-radius: 20px 20px 34px 34px;
    overflow: hidden;
    width: calc(100% - 30px);

    iframe {
      width: 100%;
    }
  }
}

.side-block:last-child {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .rocket {
    position: absolute;
    top: -150px;
    z-index: 1;
    right: -75px;
  }

  .list {
    position: relative;
    z-index: 2;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-right: 5%;

    .item {
      width: 100%;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-bottom: 30px;

      &:first-child {
        margin-top: 630px;
      }

      &:last-child {
        margin-bottom: 125px;
      }

      img {
        margin-right: 30px;
      }

      span {
        color: #fff;
        font-size: 28px;
        line-height: 120%;
        font-weight: 700;
      }
    }
  }
}

.footer {
  margin-top: auto;
  position: relative;
  z-index: 5;

  .top {
    background: #ebebeb;
    padding: 40px 0;

    p {
      hyphens: none;
      padding: 0 5%;
      color: #3e3e3e;
      font-size: 16px;
      font-weight: 400;
      line-height: 200%;
    }
  }

  .bottom {
    background-color: #836275;
    padding: 30px 0;
    color: #fbd9e6;
    font-size: 16px;

    .desk {
      display: grid;
      align-items: center;
      justify-content: center;
      grid-template-columns: 1fr 30px 1fr;
    }

    span {
      padding-left: 20px;
    }

    .link {
      color: inherit;
      text-align: right;
      padding-right: 20px;
      cursor: pointer;

      &:hover {
        color: #fff;
      }
    }
  }
}

.mob-block, .for-mob {
  display: none;
}

@media screen and (max-width: 1700px) {
  .side-block {

    &:nth-child(1) {
      width: calc(54% - 15px);
    }

    &:nth-child(2) {
      width: calc(46% - 15px);

      .rocket {
        right: -185px;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .side-block {

    &:nth-child(1) {
      width: calc(57% - 15px);
    }

    &:nth-child(2) {
      width: calc(43% - 15px);

      .rocket {
        right: -270px;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .container {
    width: 1140px;
    padding: 0;
  }

  .header {

    .container {
      padding-top: 15px;

      img {
        height: 55px;
      }
    }
  }

  .main {
    margin-top: -70px;
    padding-top: 140px;
    margin-bottom: 70px;

    .h1 {
      font-size: 52px;
    }

    .arrow {

      .arrow-img {
        margin-right: 30px;
        height: 250px;
      }

      .text {

        p {
          font-size: 28px;
          margin-bottom: 20px;
        }

        span {
          margin-bottom: 5px;
        }
      }
    }
  }

  .side-block {

    &:first-child {

      .form {
        filter: drop-shadow(0 20px 40px rgba(146, 146, 146, 0.35));
      }
    }

    &:nth-child(2) {

      .rocket {
        top: -140px;
        width: 870px;
        right: -225px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .container {
    width: 960px;
  }

  .side-block {

    &:nth-child(2) {

      .rocket {
        right: -390px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .container {
    width: 720px;
  }

  .main {
    padding-top: 110px;

    .container {
      position: relative;
    }

    .side-block {
      width: 100%;
    }
    
    .h1 {
      margin-bottom: -25px;
      position: relative;
      z-index: 1;
      
      br {
        display: none;
      }
    }

    .arrow {
      align-items: flex-start;

      .arrow-img {
        position: relative;
        z-index: 0;
        height: 210px;
      }

      .text {

        p {
          font-size: 24px;
          margin-top: 50px;
        }
      }
    }
  }

  .side-block {

    &:first-child {

      .form {
        width: 100%;
      }
    }

    &:last-child {
      align-items: center;
      position: static;

      .list {
        margin-right: 0;

        .item {

          &:first-child {
            margin-top: 40px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          span {
            color: #3e3e3e;
          }
        }
      }

      .rocket {
        right: -380px
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 540px;
  }

  .main {
    padding-top: 95px;

    .h1 {
      font-size: 42px;
      margin-bottom: 0;

      .for-mob {
        display: block;
      }
    }

    .arrow {
      margin-bottom: 20px;

      .arrow-img {
        display: none;
      }

      .text {

        p {
          margin: 17px 0;
          font-size: 24px;
        }

        img {
          margin-bottom: 0;
        }
      }
    }
  }

  .side-block {

    &:last-child {

      .rocket {
        width: auto;
        right: -115px;
        top: -95px;
      }

      .list {

        .item {

          span {
            font-size: 20px;
          }
        }
      }
    }
  }

  .footer {

    .top {
      padding: 30px 0;

      p {
        font-size: 14px;
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .container {
    width: calc(100% - 50px);
  }

  .header {

    .container {

      img {
        height: 30px;
      }
    }
  }

  .main {
    margin-top: -45px;
    padding-top: 130px;
    margin-bottom: 30px;

    .h1 {
      font-size: 28px;
    }

    .arrow {

      .text {

        p {
          padding-right: 35px;
          font-size: 18px;
        }

        span {
          font-size: 14px;
        }
      }
    }
  }

  .side-block {

    &:first-child {

      .form {
        width: calc(100% + 50px);
        margin-left: -25px;
        border-radius: 0;
        filter: none;
      }
    }

    &:last-child {

      .rocket {
        right: -25px;
        top: -130px;
      }

      .list {

        .item {
          margin-bottom: 25px;

          img {
            height: 48px;
            width: 48px;
            margin-right: 20px;
          }

          span {
            font-size: 16px;
            line-height: 21px;
          }
        }
      }
    }
  }

  .footer {

    .top {

      p {
        font-size: 12px;
        line-height: 21px;
      }
    }

    .bottom {
      padding: 15px 0;

      .desk {
        display: none;
      }

      .mob-block {
        display: flex;
        align-items: center;

        .text {
          display: flex;
          flex-direction: column;
          padding-left: 10px;

          a {
            font-size: 12px;
          }

          span {
            font-weight: 300;
            font-size: 10px;
            padding: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .main {
    padding-top: 200px;
  }

  .side-block:last-child .rocket {
    top: -200px;
  }
}
